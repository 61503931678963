import React from "react"

const VacancyCategories = ({ vacancyCategory }) => {

  return (
    <section className="content very-light-blue-background test">
    <div className="eighty-spacer"></div>

   <div className="content-block career-vacancy-block clearfix">

      <div className="centered-content-container">
         <div className="centered-title-holder">
            <h3>Highlighted Vacancies</h3>
         </div>
         <div className="sixty-spacer"></div>
      </div>

      <div className="one-flex-container">
      {vacancyCategory[0].vacancies.nodes.length
        ? vacancyCategory[0].vacancies.nodes.map((prod,index) => (
          <div className="third-flex-container staggerFadeIn stag-visible" key={index}>
             <div className="career-vacancy-details white-background">
                    <a href={`/blog/vacancy/${prod.slug}`}>
                       <h4>{prod.title}</h4>
                    </a>
                     <span className="career-vacancy-text" dangerouslySetInnerHTML={{ __html: prod.excerpt }}/>
                     <div className="find-out-more">
                         <a href={`/blog/vacancy/${prod.slug}`}>
                            Apply Now
                         </a>
                     </div>
               </div>
          </div>
        ))
      : null}
      </div>

      <div className="eighty-spacer"></div>

      <div className="find-out-more">
         <a href="/careers/career-opportunities/">
            View All Vacancies
         </a>
      </div>


      <div className="eighty-spacer"></div>

   </div>



</section>
  )
}

export default VacancyCategories
